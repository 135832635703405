<template>
  <div class="article-stat" v-if="statData">
    <div v-if="articleId > 0">
      {{ statData.article_got_it }}/{{ statData.article_cnt }}
    </div>
    <div>{{ statData.got_it }}/{{ statData.cnt }}</div>
  </div>
</template>

<script>
  import api from '@/service/api_biu';

  export default {
    name: 'ArticleStat',
    data() {
      return {
        statData: {},
      };
    },
    props: {
      articleId: {
        type: Number,
        default: 0,
      },
    },
    mounted() {
      this.refresh();
    },
    methods: {
      async refresh() {
        const res = await api.get_study_stat(this.articleId);
        if (res.code === 0) {
          this.statData = res.data;
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .article-stat {
    position: fixed;
    top: 85px;
    left: -20px;
    font-size: 26px;

    padding: 20px 50px;

    div {
      margin-bottom: 10px;
    }
  }
</style>
