import Qs from 'qs';

export default {

  getParam(name) {
    let search = location.search || '';
    search = search.substring(1);
    let q = Qs.parse(search);
    if (q[name]) {
      return q[name];
    }
    return '';
  },

  copyText(text) {
    var textArea = document.createElement("textarea");

    // Place in top-left corner of screen regardless of scroll position.
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;

    // Avoid flash of white box if rendered for any reason.
    textArea.style.background = 'transparent';

    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
  },





}
