<template>

  <div :style="'background-color:#' + bg_color + '; padding: 20px;'">
    <div
      style="position:fixed; z-index:99999999; top:40px; left:20px; width:900px; background-color: #f0f0f0; padding:20px;">

      <input v-model="image_url" placeholder="可粘贴任意文本到这里，会自动提取文本中的图片地址" style="width:100%; height:40px;"
             ref="image_input"/>
      <br/>
      <br/>
      <Button type="primary" @click="clear">
        清空
      </Button>
      <Button type="primary" @click="test">
        测试
      </Button>
      <Button type="primary" @click="backColor">
        切换背景
      </Button>
      <Button type="primary" @click="width300">
        宽度 300px
      </Button>
      <Button type="primary" @click="width100">
        宽度 100%
      </Button>
      &nbsp;
      <Checkbox v-model="auto_parse_url">自动解析 URL</Checkbox>
    </div>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <div v-for="(img,index) in image_urls" :key="index">
      <a :href="img" target="_blank">{{ img }}</a> <br>
      <img :src="img" :key="index" style="max-width:100%;" :style='imageStyle'/>
      <br>
    </div>

  </div>

</template>

<script>
export default {
  name: 'ImageView',
  data() {
    return {
      image_url: '',
      bg_color: 'ffffff',
      test_url: 'https://public-1254368367.file.myqcloud.com/kms/9ad03bed-143b-4da0-9fb3-d6a2fef4e638.png',
      imageStyle: {
        width: 'auto',
      },
      auto_parse_url: true,
    }
  },
  methods: {
    clear() {

      this.image_url = '';
      this.$refs.image_input.focus();

    },

    test() {
      this.image_url = this.test_url;
    },


    backColor() {
      this.bg_color = this.bg_color == '000000' ? 'ffffff' : '000000';
    },

    width300() {
      this.imageStyle.width = '300px';
    },

    width100() {
      this.imageStyle.width = 'auto';
    },
  },
  computed: {
    image_urls: function () {

      if (this.auto_parse_url) {
        var images = this.image_url.match(/http(s)?:\/\/[0-9a-zA-Z-.]+\/[a-zA-Z0-9/\-_%]+(\.(png|jpg|jpeg|gif|bmp)|\d+)/gi)
        if (!images || images.length === 0) {
          return [this.image_url]
        }
        return images;
      } else {
        return [this.image_url]
      }
    },
  },
  mounted() {

  },
}
</script>

<style scoped>

  .ivu-btn {
    margin-right: 5px;
  }

</style>
