import Vue from 'vue';

Vue.mixin({

  methods:{
    copyTextToClipboard(text) {
      jstool.copyText(text);
      this.$Message.success('已复制');
    },
  },

  beforeRouteEnter (to, from, next) {

    if (to.meta && to.meta.title) {
      document.title = to.meta.title + ' - 必优开发助手';
    } else {
      document.title = '必优开发助手';
    }

    next();
  },

});

