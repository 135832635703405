<template>
  <div class="container">
    <div>
      颜色值转换
    </div>
    <input v-model="source" ref="source_input" @input="onSourceChanged" style="width:200px;"/>
    <i-button type="primary" @click="clear">
      清空
    </i-button>
    <div>
      输入示例:
      <a @click="source='#707B7C';onSourceChanged();">#707B7C</a>
      &nbsp;&nbsp;&nbsp;<a @click="source='#AAF7DC6F';onSourceChanged();">#AAF7DC6F</a>
      &nbsp;&nbsp;&nbsp;<a @click="source='122,101,36';onSourceChanged();">122,101,36</a>
      &nbsp;&nbsp;&nbsp;<a @click="source='rgb(72,201,176)';onSourceChanged();">rgb(72,201,176)</a>
      &nbsp;&nbsp;&nbsp;<a @click="source='rgba(241,148,138,0.5)';onSourceChanged();">rgba(241,148,138,0.5)</a>
      <br><br>
    </div>
    <div class="result">
      <input v-model="rgb" @click="copyTextToClipboard(rgb)" readonly> <br><br>
      <input v-model="rgbWithBrackets" @focus="copyTextToClipboard(rgbWithBrackets)" readonly> <br><br>
      <input v-model="hexColor" @focus="copyTextToClipboard(hexColor)" readonly> <br><br>
      <input v-model="rgbAlpha" @focus="copyTextToClipboard(rgbAlpha)" readonly> <br><br>
      <input v-model="hexAlpha" @focus="copyTextToClipboard(hexAlpha)" readonly> <br><br>
    </div>
    <div style="position:relative;">
      <div style="height:200px;width:200px;position:absolute;background-color:#eeeeee;">
      </div>
      <div style="height:200px;width:200px;position:absolute;top:30px;left:30px;"
           :style='"background-color:" + this.rgbAlpha'>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ColorConvert',
  data() {
    return {
      source: '',
      rgb: '',
      rgbWithBrackets: '',
      hexColor: '',
      rgbAlpha: '',
      hexAlpha: '',
    }
  },
  methods: {
    onSourceChanged() {

      this.rgb = '',
        this.rgbWithBrackets = '';
      this.hexColor = '';
      this.rgbAlpha = '';
      this.hexAlpha = '';

      if (this.source === '') {
        return;
      }

      let standard = '';

      const str = this.source.replaceAll(/\s/g, '');
      const reg1 = /^\d{1,3},\d{1,3},\d{1,3}$/g;
      const rgb = str.match(reg1);
      if (rgb) {
        standard = str;
      }

      const reg2 = /^rgb\(\d{1,3},\d{1,3},\d{1,3}\)$/ig;
      const rgbWithBrackets = str.match(reg2);
      if (rgbWithBrackets) {
        standard = str.match(/\d{1,3},\d{1,3},\d{1,3}/)[0];
      }

      const reg3 = /^#[0-9A-F]{6}$/ig;
      const hexColor = str.match(reg3);
      if (hexColor) {
        standard = parseInt('0x' + str.substring(1, 3)) + ',' +
          parseInt('0x' + str.substring(3, 5)) + ',' +
          parseInt('0x' + str.substring(5, 7));
      }

      let isAlpha = false;
      let alpha = 1.000;
      const reg4 = /^rgba\(\d{1,3},\d{1,3},\d{1,3},\d{1,3}(\.\d{1,3})?\)$/ig;
      const rgbAlpha = str.match(reg4);
      if (rgbAlpha) {
        isAlpha = true;
        const match = str.match(/\d{1,3},\d{1,3},\d{1,3},\d{1,3}(\.\d{1,3})?/)[0];
        let arr = match.split(',');
        alpha = arr[3];
        arr = arr.slice(0, 3);
        standard = arr.join(',');
      }

      const reg5 = /^#[0-9A-F]{8}$/ig;
      const hexAlpha = str.match(reg5);
      if (hexAlpha) {
        isAlpha = true;
        standard =
          parseInt('0x' + str.substring(3, 5)) + ',' +
          parseInt('0x' + str.substring(5, 7)) + ',' +
          parseInt('0x' + str.substring(7, 9));
        alpha = (parseInt('0x' + str.substring(1, 3)) / 255).toFixed(3);
      }

      this.rgb = standard;
      this.rgbWithBrackets = 'rgb(' + standard + ')';

      const arr = standard.split(',');
      this.hexColor = '#' + this.toHex(arr[0]) + this.toHex(arr[1]) + this.toHex(arr[2]);

      if (isAlpha) {
        this.rgbAlpha = 'rgba(' + standard + ',' + alpha + ')';
        if (hexAlpha) {
          this.hexAlpha = str.toLowerCase();
        } else {
          this.hexAlpha = '#' + this.toHex(alpha * 255) + this.toHex(arr[0]) + this.toHex(arr[1]) + this.toHex(arr[2]);
        }
      } else {
        this.rgbAlpha = 'rgba(' + standard + ',1.0)';
        this.hexAlpha = '#ff' + this.toHex(arr[0]) + this.toHex(arr[1]) + this.toHex(arr[2]);
      }
    },
    toHex(num) {
      return parseInt(num).toString(16).padStart(2, '0').toLowerCase();
    },
    example(val) {
      return '<a @click="alert(\'aaa\')">aaa</a>';
    },
    clear() {
      this.source = '';
      this.$refs.source_input.focus();
      this.onSourceChanged();
    },

  },
  computed: {},
  mounted() {

  },
}
</script>

<style scoped lang="scss">
div.container {
  padding: 20px;
  min-height: 650px;
}

input {
  padding: 5px;
}

.ivu-btn {
  margin-left: 5px;
}

div.result {
  input {
    width: 300px;
  }
}
</style>
