<template>
  <div class="beian">
    必优开发助手
    <a href="https://beian.miit.gov.cn" target="_blank">沪ICP备2021024885号-1</a>
    <span style="width:300px;margin:0 auto; padding:0 0;">
      <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202013871"
         style="position:relative; text-decoration:none;">
        <img src="/beian.png" style="position: absolute; left: 6px; top: 18px;"/>
        <span style="margin: 0px 0px 0px 25px; color:#939393;">
          沪公网安备31011202013871号
        </span>
      </a>
    </span>
  </div>
</template>

<script>
export default {
  name: 'PageFooter',
}
</script>

<style scoped>

.beian, .beian a {
  color: #999999 !important;
  background: #eeeeee;
  padding: 20px 0 15px 0;
  width: 100%;
  text-align: center;
}
</style>
