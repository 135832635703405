<template>
  <div class="article-view" v-if="article">
    <article-stat :article-id="article.id" ref="articleStat"></article-stat>

    <BackTop style="right:auto; left:30px;"></BackTop>

    <div style="padding: 0 0 20px 22px;">
      <Button @click="back">Back</Button>
      <a
        v-if="article.last_sentence_line_num"
        style="margin-left: 20px; margin-right: 5px;"
        href="#last_sentence"
      >
        上次学习位置
      </a>

      显示译文：
      <i-switch size="small" v-model="showTranslation"></i-switch>

      <Button
        type="primary"
        size="small"
        @click="autoTranslate"
        style="margin-left: 10px;"
      >
        自动翻译
      </Button>

      <h1 class="title">
        {{ article.name }}
        <Button size="small" @click="showArticleEdit">Edit</Button>
      </h1>
      <div>
        <span v-if="article.media_path">
          Source:
          <a target="_blank" :href="article.media_path">
            {{ article.media_path }}
          </a>
        </span>
      </div>
    </div>
    <ul class="sentence" style="list-style: none;">
      <template v-for="(sentence, index) in sentenceList">
        <li
          @click="currentSentenceId = sentence.id"
          :key="index"
          style="position: relative;"
          v-if="
            !filterMode ||
              !selection ||
              sentence.content.toLowerCase().indexOf(selection.toLowerCase()) >=
                0
          "
          :class="{
            'last-sentence':
              article.last_sentence_line_num === sentence.line_num,
          }"
        >
          <Icon
            type="md-flag"
            v-if="!sentence.got_it"
            style="position: absolute; left: -15px; font-size: 20px; color: #e73333;"
          />
          <span>
            {{ sentence.content }}
          </span>
          <span v-if="showTranslation && sentence.translation">
            <br />
            {{ sentence.translation }}
          </span>

          <div class="buttons">
            <Button @click="showSentenceEdit(sentence)">
              译文/笔记
            </Button>

            <Button @click="splitSentence(sentence)">
              拆分句子
            </Button>

            <Icon
              class="icon"
              id="last_sentence"
              v-if="article.last_sentence_line_num === sentence.line_num"
              type="ios-checkmark-circle"
            />
            <Button v-else @click="updateStudyingProgress(sentence.line_num)">
              学习到此
            </Button>
          </div>
        </li>
      </template>
    </ul>
    <Button @click="back">Back</Button>

    <div
      class="word-wrapper"
      v-if="(wordList && wordList.length > 0) || selection"
    >
      <div class="word-list">
        <ul :class="{filter: filterMode}">
          <li v-for="(word, index) in wordList" :key="index">
            <Icon
              type="ios-funnel"
              class="funnel"
              :class="{select: filterMode && selection === word.content}"
              v-if="filterMode"
              @click="onWordFilter(word.content)"
            />

            {{ word.content }}

            <Poptip
              transfer
              placement="top"
              width="330"
              v-model="popupVisible[word.id]"
            >
              <Icon type="md-open" class="edit" @click="setEditWord(word)" />
              <template #content>
                <Input
                  v-model="editWord.content"
                  style="width:220px; margin-right: 10px;"
                />
                <Button @click="updateWord(word)" type="primary">保存</Button>
              </template>
            </Poptip>

            <Poptip
              transfer
              confirm
              title="Are you sure delete this word?"
              @on-ok="deleteWord(word.id)"
            >
              <Icon type="md-close-circle" class="remove" />
            </Poptip>
          </li>
        </ul>
      </div>

      <div class="word-control">
        <span>count:{{ wordList.length }}</span>
        <label style="margin:0 4px 0 8px;">筛选模式</label>
        <i-switch
          size="small"
          v-model="filterMode"
          style="margin-bottom: 2px;"
        ></i-switch>
        <span
          v-if="filterMode"
          :title="selection"
          style="margin-left: 5px; max-width:80px; max-height: 21px; text-overflow: ellipsis; overflow: hidden;"
        >
          {{ selection }}
        </span>
      </div>

      <div class="selection-word" v-if="selection && !filterMode">
        当前所选内容：
        {{ selection }}
        <br />
        <Button @click="saveWord" type="primary" size="small">
          保存到单词本
        </Button>
      </div>
    </div>

    <el-dialog
      :visible.sync="showSplitSentenceDialog"
      v-if="showSplitSentenceDialog"
      width="800px"
      title="句子拆分"
      append-to-body
      lock-scroll
      :close-on-click-modal="false"
    >
      <Input type="textarea" :rows="20" v-model="sentenceLines"></Input>
      <div style="padding: 20px 20px 0 0; text-align: right;">
        <Button type="primary" @click="saveSplitSentence">保存</Button>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="showArticleEditDialog"
      v-if="showArticleEditDialog"
      width="800px"
      title="Edit Article"
      append-to-body
      lock-scroll
      :close-on-click-modal="false"
    >
      <Input placeholder="source" v-model="editArticle.media_path"></Input>
      <div style="padding: 20px 20px 0 0; text-align: right;">
        <Button type="primary" @click="updateArticle">保存</Button>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="showSentenceEditDialog"
      v-if="showSentenceEditDialog"
      width="800px"
      title="Translate/Note"
      append-to-body
      lock-scroll
      :close-on-click-modal="false"
    >
      <p style="min-height: 80px;">
        原文：
        <br />
        <span v-if="!hideOrigin && noDiffContent">
          {{ editSentence.content }}
        </span>
        <span id="display" style="position: relative;"></span>
      </p>
      <Input
        placeholder="Translation"
        type="textarea"
        :rows="5"
        v-model="editSentence.translation"
      ></Input>

      <textarea
        v-show="dictationMode"
        wrap="soft"
        id="input_dictation"
        autocomplete="off"
        spellcheck="false"
        placeholder=""
        rows="5"
        style="margin-top: 10px; padding-left: 1px; padding-right: 1px;"
        class="ivu-input"
      ></textarea>
      <div style="display: flex;">
        <div style="padding: 20px 20px 0 0; text-align: left; flex: auto;">
          <Button
            @click="gotIt"
            :type="editSentence.got_it ? 'success' : 'default'"
            style="margin-left: 5px;"
          >
            Got it!
          </Button>
        </div>
        <div style="padding: 20px 20px 0 0; text-align: right;">
          默写：
          <i-switch size="small" v-model="dictationMode"></i-switch>
          隐藏原文：
          <i-switch size="small" v-model="hideOrigin"></i-switch>
          <Button @click="prevSentence" style="margin-left: 5px;">
            上一句
          </Button>
          <Button @click="nextSentence" style="margin-left: 5px;">
            下一句
          </Button>
          <Button
            type="primary"
            @click="saveSentence"
            style="margin-left: 5px;"
          >
            保存
          </Button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import api from '@/service/api_biu';
  import {diffChars, diffWords} from 'diff';
  import ArticleStat from '../stat/ArticleStat';
  import tools from '@/service/biu_tools';

  export default {
    name: 'ArticleView',
    components: {ArticleStat},
    data() {
      return {
        article: null,
        sentenceList: null,
        selection: '',
        currentSentenceId: -1,
        wordList: null,
        editWord: {},
        popupVisible: [],
        showSplitSentenceDialog: false,
        sentenceLines: '',
        editSentence: null,

        showArticleEditDialog: false,
        editArticle: null,

        showSentenceEditDialog: false,
        translation: '',

        showTranslation: false,

        hideOrigin: false,
        noDiffContent: true,

        dictationMode: false,

        filterMode: false,

        debounceShowDiff: null,
      };
    },
    async mounted() {
      let response = await api.get_article(this.$route.params.article_id);
      if (response.code === 0) {
        this.article = response.data.article;
        this.sentenceList = response.data.sentence_list;

        this.loadWordList();
      }

      document.addEventListener('mouseup', this.onMouseUp);
      document.addEventListener('keydown', this.onKeydown);

      this.debounceShowDiff = tools.debounce(this.showDiff, 100);
    },
    methods: {
      back() {
        this.$router.push('/page/studying/article/list');
      },

      async updateStudyingProgress(line_num) {
        const res = await api.update_article({
          id: this.article.id,
          last_sentence_line_num: line_num,
        });
        if (res.code === 0) {
          this.article.last_sentence_line_num = line_num;
        }
      },

      onMouseUp(e) {
        this.$nextTick(() => {
          const selectContent = document
            .getSelection()
            ?.toString()
            .trim()
            .toLowerCase();
          if (selectContent) {
            this.selection = selectContent;
          } else {
            if (!this.filterMode) {
              this.selection = '';
            }
          }
        });
      },

      onKeydown(e) {
        if (this.showSentenceEditDialog) {
          if (e.key === 'Enter' && e.ctrlKey) {
            this.hideOrigin = !this.hideOrigin;
          } else if (e.key === '.' && e.ctrlKey) {
            this.nextSentence();
          } else if (e.key === ',' && e.ctrlKey) {
            this.prevSentence();
          } else if (e.key === 'm' && e.ctrlKey) {
            this.gotIt();
          } else if (e.key === 'i' && e.ctrlKey) {
            this.autoComplete();
          }
        }
      },

      async saveWord() {
        const res = await api.insert_word({
          word: this.selection,
          article_id: this.article.id,
          sentence_id: this.currentSentenceId,
        });
        if (res.code === 0) {
          this.loadWordList();
        }
      },

      async loadWordList() {
        const res = await api.get_word_list(this.article.id, 'new');
        if (res.code === 0) {
          this.wordList = res.data;
        }
      },

      async deleteWord(wordId) {
        const res = await api.delete_word(wordId);
        if (res.code === 0) {
          this.loadWordList();
        }
      },

      setEditWord(word) {
        this.$set(this, 'editWord', {
          id: word.id,
          content: word.content,
          article_id: this.article.id,
        });
      },

      async updateWord(word) {
        const res = await api.update_word(this.editWord);
        if (res.code === 0) {
          this.$set(this.popupVisible, this.editWord.id, false);
          word.content = this.editWord.content;
        }
      },

      splitSentence(sentence) {
        this.editSentence = sentence;
        this.sentenceLines = sentence.content.replaceAll(/\./g, '.\n\n');
        this.showSplitSentenceDialog = true;
      },

      async showSentenceEdit(sentence) {
        this.editSentence = sentence;
        if (!this.showSentenceEditDialog) {
          this.showSentenceEditDialog = true;
          this.$nextTick(() => {
            const input = document.getElementById('input_dictation');
            input.addEventListener('keyup', this.debounceShowDiff);
          });
        }
        if (!this.editSentence.translation) {
          const res = await api.translate(sentence.content);
          if (res && res.sentences && res.sentences.length > 0) {
            const translation = res.sentences.map(x => x.trans).join('');
            this.$set(this.editSentence, 'translation', translation);
          }
        }
        this.noDiffContent = true;
        this.hideOrigin = this.dictationMode;
      },

      prevSentence() {
        const index = this.sentenceList.indexOf(this.editSentence);
        if (index > 0) {
          this.showSentenceEdit(this.sentenceList[index - 1]);
          this.hideOrigin = this.dictationMode;
          document.getElementById('input_dictation').value = '';
          this.dictation = '';
        }
      },

      nextSentence() {
        const index = this.sentenceList.indexOf(this.editSentence);
        if (index < this.sentenceList.length - 1) {
          this.showSentenceEdit(this.sentenceList[index + 1]);
          this.hideOrigin = this.dictationMode;
          document.getElementById('input_dictation').value = '';
        }
      },
      async saveSentence() {
        const data = {
          id: this.editSentence.id,
          translation: this.editSentence.translation,
        };
        const res = await api.update_sentence(data);
        if (res.code === 0) {
          this.showSentenceEditDialog = false;
          this.$Message.success('保存成功');
        }
      },

      async gotIt() {
        const data = {
          id: this.editSentence.id,
          got_it: 1,
        };
        const res = await api.update_sentence(data);
        if (res.code === 0) {
          this.$set(this.editSentence, 'got_it', 1);
          setTimeout(() => {
            this.nextSentence();
            this.$refs.articleStat.refresh();
          }, 500);
        }
      },

      async saveSplitSentence() {
        const res = await api.split_sentence({
          sentence_id: this.editSentence.id,
          lines: this.sentenceLines,
        });
        if (res.code === 0) {
          let response = await api.get_article(this.$route.params.article_id);
          if (response.code === 0) {
            this.article = response.data.article;
            this.sentenceList = response.data.sentence_list;
            this.showSplitSentenceDialog = false;
          }
        }
      },

      showArticleEdit() {
        this.showArticleEditDialog = true;
        this.$set(this, 'editArticle', {
          id: this.article.id,
          media_path: this.article.media_path || '',
        });
      },

      async updateArticle() {
        const res = await api.update_article({
          id: this.article.id,
          media_path: this.editArticle.media_path,
        });
        if (res.code === 0) {
          this.article.media_path = this.editArticle.media_path;
          this.showArticleEditDialog = false;
        }
      },

      onWordFilter(word) {
        if (this.filterMode) {
          this.selection = word;
        }
      },

      async autoTranslate() {
        this.showTranslation = true;
        for (const sentence of this.sentenceList) {
          if (!sentence.translation) {
            let res = await api.translate(sentence.content);
            if (res && res.sentences && res.sentences.length > 0) {
              const translation = res.sentences.map(x => x.trans).join('');
              const data = {
                id: sentence.id,
                translation: translation,
              };
              res = await api.update_sentence(data);
              this.$set(sentence, 'translation', translation);
            }
          }
        }

        this.$Message.success('翻译完成');
      },

      showDiff() {
        this.noDiffContent = true;

        if (this.hideOrigin) {
          return;
        }

        const display = document.getElementById('display');
        display.innerHTML = '';

        const origin = this.editSentence.content;
        const input = document.getElementById('input_dictation').value;
        if (!input) {
          return;
        }

        const diff = diffChars(origin, input, {ignoreCase: true});
        const fragment = document.createDocumentFragment();

        diff.forEach(function(part) {
          // green for additions, red for deletions
          // grey for common parts
          const color = part.added ? 'green' : part.removed ? 'red' : 'grey';
          const span = document.createElement('span');
          span.style.color = color;
          if (color === 'green') {
            span.appendChild(document.createTextNode(part.value));
            span.style = 'position: absolute; top: -13px; color: green;';
          } else {
            span.appendChild(document.createTextNode(part.value));
          }
          fragment.appendChild(span);
        });

        display.appendChild(fragment);
        this.noDiffContent = false;
      },
      autoComplete() {
        const input = document.getElementById('input_dictation');
        const inputValue = input.value.trim();
        if (!inputValue || inputValue === '') {
          return;
        }
        const origin = this.editSentence.content;

        const inputWords = inputValue.split(' ');
        const originWords = origin.split(' ');

        const lastIndex = inputWords.length - 1;
        if (
          originWords[lastIndex]
            .toLowerCase()
            .startsWith(inputWords[lastIndex].toLowerCase())
        ) {
          inputWords[lastIndex] = originWords[lastIndex];
          input.value = inputWords.join(' ') + ' ';
        }
      },
    }, // methods

    watch: {
      dictationMode(val) {
        this.hideOrigin = val;
      },

      hideOrigin(val) {
        let display = document.getElementById('display');
        display.innerHTML = '';

        if (val === false && this.dictationMode) {
          this.showDiff();
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .article-view {
    padding: 20px 120px;

    .title {
      margin: 10px 0;
      cursor: pointer;
      width: 100%;

      button {
        margin-left: 10px;
        visibility: hidden;
      }

      &:hover {
        button {
          visibility: visible;
        }
      }
    }

    .sentence {
      margin-left: 15px;

      li {
        margin-bottom: 5px;
        padding: 10px 10px 45px 10px;
        width: 800px;
        position: relative;

        &.last-sentence {
          background-color: #f0fff6;
        }

        .buttons {
          position: absolute;
          right: 10px;
          bottom: 10px;
          line-height: 30px;

          .icon {
            font-size: 24px;
            color: #19be6b;
            position: relative;
            top: 3px;
          }

          button {
            visibility: hidden;
            margin-right: 10px;
          }
        }

        &:hover {
          background-color: #f8f8f8;

          .buttons {
            button {
              visibility: visible;
            }
          }
        }
      }
    }
  }

  .word-wrapper {
    position: fixed;
    left: 980px;
    top: 50px;
    width: 300px;
    min-height: 200px;
    max-height: 92vh;
    border-radius: 4px;
    background-color: #fafafa;
    box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.3);
    padding: 5px;

    .word-list {
      max-height: 70vh;
      overflow-y: auto;

      ul.filter {
        list-style-type: none;
      }

      &::-webkit-scrollbar-track-piece {
        background: #faffff;
      }

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: #bed5ff;
        border-radius: 20px;
      }

      ul {
        margin: 0 0 0 30px;

        li {
          padding: 2px 10px 2px 0;
          position: relative;

          .funnel,
          .remove,
          .edit {
            visibility: hidden;
            color: #de837f;
            font-size: 16px;
            margin: 0 0 0 5px;

            &:hover {
              cursor: pointer;
            }
          }

          .edit {
            color: #409eff;
          }

          .funnel {
            color: #c5c5c5;
            visibility: visible;
            position: absolute;
            left: -26px;
            top: 5px;

            &.select {
              color: #606c76;
            }
          }

          &:hover {
            .funnel,
            .remove,
            .edit {
              visibility: visible;
            }
          }
        }
      }
    }

    .word-control {
      padding: 5px 0 0 5px;
      display: flex;
      align-items: center;
    }

    .selection-word {
      margin: 4px 0 0 0;
      height: 12vh;
      overflow: auto;
      border-top: 2px solid #409eff;
      padding: 10px 0 30px 10px;

      &::-webkit-scrollbar-track-piece {
        background: #faffff;
      }

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: #bed5ff;
        border-radius: 20px;
      }

      button {
        // margin: 5px 0;
        position: absolute;
        bottom: 10px;
        right: 10px;
      }
    }
  }
</style>
